<template>
  <div class="wallet">
    <h1>{{ $t("wallet.title") }}</h1>
    <div class="d-flex align-center justify-center height100">
      <div class="text-h5">{{ $t("wallet.description") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>

<style lang="scss">
.wallet {
  min-height: calc(100vh - 91px) !important;
}
</style>
